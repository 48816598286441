import React from 'react';
import {Route} from 'react-router';
import {BrowserRouter, Switch} from 'react-router-dom';
import HomePage from '../pages/HomePage/Home';

const Navigation = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/v1/limor/podcasts/:id" component={HomePage} />
                <Route exact path="/:url" component={HomePage} />
            </Switch>
        </BrowserRouter>
    )
}

export default Navigation;
