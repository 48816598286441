import React, {useState, useRef, useEffect} from 'react';
import moment from 'moment';
import axios from 'axios';
import {useDispatch} from 'react-redux';
import {addListens} from './../../store/actions';
import config from './../../config/dev';
import configStaging from './../../config/staging';
import configProduction from './../../config/prod';

const VideoPlayer = (props) => {
    const [play, setPlay] = useState(true);
    //const audio = useRef(null);
    const dispatch = useDispatch();
    const [trackProgress, setTrackProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const intervalRef = useRef();
    const [ownerDetails, setOwnerDetails] = useState([]);
    const audioRef = useRef(new Audio((props.audioUrlLink) && props.audioUrlLink));
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying]);

    useEffect(() => {
        return () => {
          //audioRef.current.pause();
          clearInterval(intervalRef.current);
        }
    }, []);

    const startTimer = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
          // if (audioRef.current.currentTime >= 180) {
          //       audioRef.current.currentTime = 0;
          // } else {
          //
          // }
          setTrackProgress(audioRef.current.currentTime);
        }, [1000]);
    }

    useEffect(() => {
        setTrackProgress(trackProgress);
    }, [trackProgress])

    const onScrub = (value) => {
        clearInterval(intervalRef.current);
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
        startTimer();
    }

    const handlePlay = () => {
        if(play === true){
            audioRef.current.play();
            setIsPlaying(true);
            startTimer();
            let queryParams = window.location.pathname.split("=");
            let getPodcastId = queryParams[1];
            //dispatch(addListens(getPodcastId));
            if(window.origin === "https://limor-5439b.web.app") {
                axios.post(config.constants.ENV.BASE_URL + "podcasts/"+ getPodcastId +"/listens")
            } else if (window.origin  === "https://limor-staging.web.app") {
                axios.post(configStaging.constants.ENV.BASE_URL + "podcasts/"+ getPodcastId +"/listens")
            } else if (window.origin === "https://limor-prod.web.app" || window.origin === "https://castpreview.limor.ie") {
                axios.post(configProduction.constants.ENV.BASE_URL + "podcasts/"+ getPodcastId +"/listens")
            } else {
                axios.post(config.constants.ENV.BASE_URL + "podcasts/"+ getPodcastId +"/listens")
            }
        } else {
            audioRef.current.pause();
            setIsPlaying(false);
        }
        setPlay(!play);
    }

    const handlePlayClicked = (data) => {
        if(data === "forward" && trackProgress <= (audioRef.current.duration - 5)){
            clearInterval(intervalRef.current);
            audioRef.current.currentTime = audioRef.current.currentTime + 5.0;
            setTrackProgress(audioRef.current.currentTime);
            startTimer();
        } else if(trackProgress <= audioRef.current.duration) {
            clearInterval(intervalRef.current);
            audioRef.current.currentTime = audioRef.current.currentTime - 5.0;
            setTrackProgress(audioRef.current.currentTime);
            startTimer();
        }
    }

    const setDefaultTime = (second) => {
        let time;
        var s = parseInt(Math.round(second) % 60);
        var m = parseInt((Math.round(second) / 60) % 60);
        if (s < 10) {
            time = m + ':0' + s;
        } else {
            time = m + ':' + s;
        }
        return time;
    }

    const onLoadedMetadata = () => {
        if (audioRef.current) {
            setDuration(audioRef.current.duration)
        }
    }

    useEffect(() => {
        setOwnerDetails(props.audioDetails);
    }, [props.audioDetails]);


    return(
        <div className="positionRelative">
            <div className="videoPlayerDetails">
                <img src={(ownerDetails.length > 0) ? ownerDetails[0].owner.images.original_url : "/assets/images/default-profile-avatar.png"} alt="Avatar" className="avatarCls" />
                {/* <img src="/assets/images/profile.svg" alt="Avatar" className="avatarCls" /> */}
                <label className="ml-3 text-16 fw-bold text-white">
                    {(ownerDetails.length > 0) && ownerDetails[0].owner.username}<br />
                    {/* Joe <br /> */}
                    <span className="text-12 fw-200">
                        {(props.audioDetails[0].created_at) ? moment.utc(props.audioDetails[0].created_at ).local().startOf('seconds').fromNow(): "2 days ago"}
                        - Ireland</span>
                </label>
                <button className="dotBtn"><img src="/assets/icons/dots.svg" alt="dots" onClick={() => props.openDowloadOption()} /></button>
            </div>
            {/* <img src={(ownerDetails.length > 0) ? ownerDetails[0].owner.images.original_url : "/assets/images/race.svg"} alt="Avatar" className="avatarCls" /> */}
            {/* <img src="/assets/images/race.svg" alt="Race Car" className="raceImg" />  */}
            <img src={(props.audioDetails[0].images.large_url) ? props.audioDetails[0].images.large_url : "/assets/images/race.svg"} alt="Avatar" className="raceImg" onClick={handlePlay} />
            <div className="BoxShadowCls">
            <div className="video">
                <div className="audioDuration">
                    <span style={{float: 'left'}} id="duration">{(trackProgress > 0.0) ? setDefaultTime(trackProgress) : "0:00"}</span>
                    {/* <span style={{float: 'right'}} id="timeleft">{"3:00"}</span> */}
                    <span style={{float: 'right'}} id="timeleft">{setDefaultTime(Math.round(duration))}</span>
                </div>
                <div className="range-slider">
                    <input
                        type="range"
                        value={trackProgress}
                        min="0"
                        max={Math.round(audioRef.current.duration)}
                        className="range-slider__range"
                        onChange={(e) => onScrub(e.target.value)}
                    />
                </div>
                <div>
                    <audio className="video__content" id="player" onLoadedMetadata={onLoadedMetadata} ref={audioRef} controls disablePictureInPicture controlsList="nodownload">
                        <source src={(props.audioUrlLink) && props.audioUrlLink} type="audio/ogg" />
                        <source src={(props.audioUrlLink) && props.audioUrlLink} type="audio/mp4" />
                        <source src={(props.audioUrlLink) && props.audioUrlLink} type="audio/mpeg" />
                        Your browser does not support the video tag.
                    </audio>
                </div>
            </div>
            <div className="controls">
                <img src="/assets/icons/backward.svg" alt="Speed control"  onClick={() => handlePlayClicked('backward')} />
                <img src={(!play || trackProgress === duration) ? "/assets/icons/pause.png" : "/assets/icons/play.svg"} className="controlsplay" alt="Speed control" width="20px" height="22px" onClick={handlePlay} />
                <img src="/assets/icons/forward.svg" alt="Speed control" onClick={() => handlePlayClicked('forward')} />
            </div>
            </div>
        </div>
    )
}

export default VideoPlayer;

