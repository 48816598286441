import React from 'react';

const DownloadApp = (props) => {
    const {modal} = props;
    
    const appLink = (linkUrl) => {
        window.location.replace(linkUrl);
    }

    return(
        <div>
            <button className="googlePlayBtn" onClick={() => appLink('https://play.google.com/store/apps/details?id=com.limor.app&hl=en_IN&gl=US')}><img src="/assets/icons/googleplay.svg" alt="Google Play Download" />{(modal) ? "Download From Google Play" : "Download Limor From Google Play" }</button>
            <button className="appleStoreBtn" onClick={() => appLink('https://apps.apple.com/in/app/limor/id1151545350')}><img src="/assets/icons/apple.svg" alt="Apple Store Download" />{(modal) ? "Download From App Store" : "Download Limor From App Store"}</button>
        </div>
    )
}

export default DownloadApp;