module.exports = {
    name: "dev.config",
    constants: {
      ENV: {
        BASE_URL: "https://previewapi.dev.limor.ie/v1/limor/",
        ICON_URL: "/assets/icons/",
        IMAGE_URL: "/assets/images/",
      },
    },
};
  