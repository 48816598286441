import React, {useState, useEffect} from 'react';
import Header from './../../components/Header';
import DownloadApp from './../../components/DownloadApp';
import AudioPlayer from './../../components/AudioPlayer';
import Modal from './../../components/Modal';
import optionsJson from './../../static/optionsData.json';
import useWindowDimensions from './../../components/WindowDimensions';
import {useDispatch, connect} from 'react-redux';
import {getPodcastDetails} from './../../store/actions';
import {Helmet} from "react-helmet";

const Home = ({getPodcastByIdDetails}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { height, width } = useWindowDimensions();
    const [options, setOptions] = useState(optionsJson);
    const [toggleMobile, setToggleMobile] = useState(false);
    const [podcastTitle, setPodcastTitle] = useState("");
    const [podcastCaption, setPodcastCaption] = useState("");
    const [podcastImg, setPodcastImg] = useState("");

    const appLink = (linkUrl) => {
        window.location.replace(linkUrl);
    }

    const validTag = (content) => {
        if(content.indexOf('#') > -1) {
            return true;
        } else {
            return false;
        }
    }
 
    useEffect(() => {
        if(width <= 576){
            setToggleMobile(true);
        }else{
            setToggleMobile(false); 
        }
    }, [width, height]);

    useEffect(() => {
        if(window.location.href.includes("&")) {
            let setUrl = window.location.href.substring(0, window.location.href.indexOf('&'));
            let queryParams = setUrl.split("=");
            let getPodcastId = queryParams[1];
            if(getPodcastByIdDetails.length === 0) {
                dispatch(getPodcastDetails(getPodcastId));
            }
        } else {
            let queryParams = window.location.href.split("=");
            let getPodcastId = queryParams[1];
            if(getPodcastByIdDetails.length === 0) {
                dispatch(getPodcastDetails(getPodcastId));
            }
        }
    }, []);

    useEffect(() => {
        if (getPodcastByIdDetails.length > 0) {
            let changeOptions = options.map((data, id) => {
                if(data.text === "heart"){
                    data.value = getPodcastByIdDetails[0].number_of_likes
                }
                if(data.text === "retweet"){
                    data.value = getPodcastByIdDetails[0].number_of_listens
                }
                if(data.text === "comment"){
                    data.value = getPodcastByIdDetails[0].number_of_comments
                }
                if(data.text === "union"){
                    data.value = getPodcastByIdDetails[0].number_of_recasts
                }
                return data;
            })
            setOptions(changeOptions);
            setPodcastTitle(getPodcastByIdDetails[0].title);
            setPodcastCaption(getPodcastByIdDetails[0].caption);
            setPodcastImg(getPodcastByIdDetails[0].images.original_url)
        }
    }, [getPodcastByIdDetails]);

    return(
        <>
            <Helmet>
                <meta property='og:title' content={podcastTitle} />
                <meta property='og:image' content={podcastImg} />
                <meta property='og:description' content={podcastCaption} />
                <meta property='og:url' content={window.location.href} />
            </Helmet>
            {(!toggleMobile) && <Header />}
            <div className="homeMobileView">
                {(toggleMobile) && <DownloadApp modal={false} />}
                {open && 
                    <Modal closeModal={() => setOpen(false)}>
                        <div className="text-center">
                            <img src="/assets/icons/limor-logo.svg" alt="Limor Logo" className="mb-3" />
                            <h5 className="mt-3">Download Limor & Register Your Account</h5>
                            <p className="mb-4 greyColorText">Listen to the full cast and join the conversation by following this user on Limor.</p>
                            <DownloadApp modal={true} />
                        </div>
                    </Modal>
                }
                {(toggleMobile) &&  <img src="/assets/icons/limor-logo-text.svg" alt="Limor Logo" className="mt-3 mb-3" />}
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 cardViewCls">
                            {(getPodcastByIdDetails.length > 0) && <AudioPlayer openDowloadOption={() => setOpen(true)} toggleMobile={toggleMobile} audioUrlLink={(getPodcastByIdDetails[0].audio) && getPodcastByIdDetails[0].audio.audio_url} audioDetails={getPodcastByIdDetails} /> }
                            {/* <AudioPlayer  /> */}
                            <div className="videoDetails">
                                <h5>{(getPodcastByIdDetails.length > 0) ? getPodcastByIdDetails[0].title : "" }</h5>
                                <p className="greyColorText">
                                {(getPodcastByIdDetails.length > 0) ?
                                    getPodcastByIdDetails[0].caption :
                                    "" 
                                } 
                                {/* <span className="seeMoreText">See more...</span> */}
                                </p>
                                
                                {/* {(getPodcastByIdDetails[0] && getPodcastByIdDetails[0].caption.match(/#[a-z]+/gi).map((data, id) => {
                                    return(
                                        <label key={id} className="tagText">{data}</label>
                                    )
                                }))} */}
                                {(getPodcastByIdDetails[0] && validTag(getPodcastByIdDetails[0].caption) === true) &&
                                <>
                                {(getPodcastByIdDetails[0].caption.match(/#[a-z]+/gi).map((data, id) => {
                                    return(
                                        <label key={id} className="tagText">{data}</label>
                                    )
                                }))}
                                </>} 
                                <hr />
                                <div>
                                    {(options && options.map((data, id) => {
                                        return(
                                            <span key={id}>
                                                <img src={data.icon} alt={data.text} className="mr-1" onClick={() => setOpen(true)} />
                                                <label className="greyColorText mr-3">{data.value}</label>
                                            </span>
                                        )
                                    }))}
                                </div>
                            </div>
                        </div>
                        {(!toggleMobile) && 
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-center margin-t-100">
                            <img src="/assets/images/mobile-view.svg" alt="Mobile download" />
                            <h3 className="mt-5 mb-3">Download Limor <br /> & <br />Register Your Account</h3>
                            <p className="greyColorText mb-3">
                                Listen to the full cast and join the conversation <br /> by following this user on Limor.
                            </p>
                            <img src="/assets/images/googleplay.svg" alt="google Play" className="mr-4 cursor-pointer" onClick={() => appLink('https://play.google.com/store/apps/details?id=com.limor.app&hl=en_IN&gl=US')} />
                            <img src="/assets/images/appStore.svg" alt="App Store" className="cursor-pointer" onClick={() => appLink('https://apps.apple.com/in/app/limor/id1151545350')} />
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        getPodcastByIdDetails: state.homeDetails.podcastData
    }
}

export default connect(mapStateToProps)(Home);