import React from 'react';

const Header = () => {
    return(
        <div className="headerBox">
            <div className="container padding-0">
                <img src="/assets/icons/limor-logo-text.svg" alt="Limor Logo" />
            </div>
        </div>
    )
}

export default Header;