import {getPodcastbyId, addListens} from './../../utility/httpRequest/ApiUrlMapping';

export function getPodcastByIdDetails (podcastId) {
    return function(dispatch) {
        return getPodcastbyId(podcastId).then(res => {
            dispatch({
                type: "GET_PODCAST_BY_ID_SUCCESS",
                payload: res.data.data.podcast
            });
        })
        .catch(error => {
            dispatch({
                type: "GET_PODCAST_BY_ID_FAILURE",
                payload: error
            });
        })
    };
}


export function addListensDetails (podcastId) {
    return function(dispatch) {
        return addListens(podcastId).then(res => {
            dispatch({
                type: "GET_LISTENS_SUCCESS",
                payload: res.data.data.podcast
            });
        })
        .catch(error => {
            dispatch({
                type: "GET_LISTENS_FAILURE",
                payload: error
            });
        })
    };
}
