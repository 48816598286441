import {getPodcastByIdDetails, addListensDetails} from './../middlewares';

export function getPodcastDetails(podcastId) {
    return getPodcastByIdDetails(podcastId);
}


export function addListens(podcastId) {
    return addListensDetails(podcastId);
}

