import React, {useState, useEffect} from 'react';
import useWindowDimensions from './../WindowDimensions';

const Modal = (props) => {
  const [toggleMobile, setToggleMobile] = useState(false);
  const { height, width } = useWindowDimensions();
  const { closeModal } = props;

  useEffect(() => {
    if(width <= 576){
        setToggleMobile(true);
    }else{
        setToggleMobile(false); 
    }
}, [width, height])

  return (
    <div className="overlay">
      <div className="content" style={{width: (!toggleMobile) ? "50%" : "90%"}}>
        <span><img src="/assets/icons/close.svg" className="float-right" alt="Close Icon" onClick={closeModal} /></span>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;