export function getEnv() {
    if(window.origin === "https://limor-5439b.web.app") {
        return "development";
    } else if (window.origin  === "https://limor-staging.web.app") {
        return "staging";
    } else if (window.origin === "https://limor-prod.web.app" || window.origin === "https://castpreview.limor.ie") {
        return "production";
    } else {
        return "development";
    }
    //return process.env.hasOwnProperty('REACT_APP_ENV')  && process.env.NODE_ENV ? process.env.NODE_ENV : 'staging';
}
