let initialState = {
  podcastData: []
};

const getHomePageData = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PODCAST_BY_ID_SUCCESS':
      return {
        ...state,
        podcastData: state.podcastData.concat(action.payload)
      }
    default:
      return state;
  }
};

export default getHomePageData;
