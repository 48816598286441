import HttpClient from './HttpRequest';
import config from './../../config/dev';
import configStaging from './../../config/staging';
import configProduction from './../../config/prod';

export async function getPodcastbyId(podcastId) {
    if(window.origin === "https://limor-5439b.web.app") {
        return HttpClient.get(config.constants.ENV.BASE_URL + 'podcasts/' + podcastId);
    } else if (window.origin  === "https://limor-staging.web.app") {
        return HttpClient.get(configStaging.constants.ENV.BASE_URL + 'podcasts/' + podcastId);
    } else if (window.origin === "https://limor-prod.web.app" || window.origin === "https://castpreview.limor.ie") {
        return HttpClient.get(configProduction.constants.ENV.BASE_URL + 'podcasts/' + podcastId);
    } else {
        return HttpClient.get(config.constants.ENV.BASE_URL + 'podcasts/' + podcastId);
    }
}

export async function addListens(podcastId) {
    if(window.origin === "https://limor-5439b.web.app") {
        return HttpClient.post(config.constants.ENV.BASE_URL + "podcasts/"+ podcastId +"/listens");
    } else if (window.origin  === "https://limor-staging.web.app") {
        return HttpClient.post(configStaging.constants.ENV.BASE_URL + "podcasts/"+ podcastId +"/listens");
    } else if (window.origin === "https://limor-prod.web.app" || window.origin === "https://castpreview.limor.ie") {
        return HttpClient.post(configProduction.constants.ENV.BASE_URL + "podcasts/"+ podcastId +"/listens");
    } else {
        return HttpClient.post(config.constants.ENV.BASE_URL + "podcasts/"+ podcastId +"/listens");
    }
}

